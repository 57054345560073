import "../views/stylesheets/imageCropper.css";
import React, { useState, useCallback, useEffect } from "react";
import cross from "./../images/cross-black.svg";
import edit from "./../images/edit-image.svg";
import Cropper from "react-easy-crop";
import Popup from "reactjs-popup";

const ImageCropper = ({ imageSrc, onCropComplete }) => {
  const [isOpen, setIsOpen] = useState(true);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [originalAspectRatio, setOriginalAspectRatio] = useState(1);

  const onCropChange = setCrop;
  const onZoomChange = setZoom;

  const onCropCompleteHandler = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCrop = async (close) => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onCropComplete(croppedImage);
      close();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getAspectRatio = () => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageSrc;

        img.onload = () => {
          const aspectRatio = img.naturalWidth / img.naturalHeight;
          resolve(aspectRatio);
        };

        img.onerror = (error) => {
          reject("Error loading image: " + error);
        };
      });
    };

    const calculateAspectRatio = async () => {
      try {
        const aspectRatio = await getAspectRatio();
        setOriginalAspectRatio(aspectRatio);
        return aspectRatio;
      } catch (error) {
        console.error(error);
      }
    };

    calculateAspectRatio();
  }, [imageSrc]);

  return (
    <Popup
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() =>
        handleCrop(() => {
          setIsOpen(false);
        })
      }
      className="share-popup crop-popup"
      trigger={
        <div className="crop-popup-trigger">
          Adjust <img src={edit} alt="" />
        </div>
      }
      modal
    >
      {(close) => (
        <>
          <div className="share-popup-heading">
            <h3>Crop & Adjust</h3>
            <img
              src={cross}
              alt=""
              onClick={() => {
                handleCrop(close);
              }}
            />
          </div>
          <div
            className="crop-main-container"
            style={{ aspectRatio: originalAspectRatio }}
          >
            <div className="crop-sub-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropCompleteHandler}
              />
            </div>
          </div>
          <div
            onClick={() => {
              handleCrop(close);
            }}
            className="crop-popup-trigger crop-popup-done-button"
          >
            Crop Image
          </div>
        </>
      )}
    </Popup>
  );
};

export default ImageCropper;

function getCroppedImg(imageSrc, crop) {
  const image = new Image();
  image.src = imageSrc;

  return new Promise((resolve) => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    };
  });
}
