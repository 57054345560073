import "./../stylesheets/upload.css";
import Artwork from "./../../images/artwork-thumbnail.png";
import Tick from "./../../images/thumbnail-tick.png";
import Remove from "./../../images/remove-thumbnail.png";
import Info from "./../../images/i-btn.png";
import UploadBtn from "./../../images/upload-btn2.png";
import UploadCloud from "./../../images/upload-cloud.png";
import Warning from "./../../images/warning.svg";
import MusicBtn from "./../../images/music.png";
import downBtn from "./../../images/down-button-dark.png";
import cross from "./../../images/cross-black.svg";
import more from "./../../images/more-dark.svg";
import { Circle } from "rc-progress";
import * as Sentry from "@sentry/react";
import React, { useRef, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import ImageCropper from "../../utils/ImageCropper";

import api from "../../utils/api";
function UploadBeat({ setShowUnauthenticatedPopup, uploadSuccess }) {
  const { navigate } = useNavigation();
  const { user, isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates, formatCurrency, convertRate } =
    useCurrencyContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [popupGenre, setPopupGenre] = useState([]);
  const [BPM, setBPM] = useState(null);
  const [price, setPrice] = useState(null);
  const [enableBidding, setEnableBidding] = useState("no");
  const [downloadOptions, setDownloadOptions] = useState("no");
  const [licenseType, setLicenseType] = useState("no");
  const [popupStates, setPopupStates] = useState({
    genre: false,
    files: false,
  });
  const [saveChanges, setSaveChanges] = useState(false);
  const [progress, setProgress] = useState(5);
  const [musicFileError, setMusicFileError] = useState(
    "Please provide a preview file!"
  );
  const [popupFiles, setPopupFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [selectedStreamingFile, setSelectedStreamingFile] = useState(null);
  const [selectedDownloadFile, setSelectedDownloadFile] = useState(null);
  const [selectedUploadFile, setSelectedUploadFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const downloadFileInputRef = useRef(null);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getUser = async () => {
      if (user && user.role !== "seller") {
        navigate("/");
      }
    };

    if (!isAuthenticated) {
      navigate("/login");
      return;
    } else {
      getUser();
    }
  }, [navigate, isAuthenticated, user]);

  useEffect(() => {
    const getFileParts = async function () {
      try {
        const response = await api.get(`parts`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        const uniqueParts = [];
        const uniqueNames = new Set();

        data.data.forEach((part) => {
          if (!uniqueNames.has(part.name)) {
            uniqueNames.add(part.name);
            uniqueParts.push(part);
          }
        });

        const initialFiles = uniqueParts.map((part) => ({
          name: part.name,
          id: part.id,
          checked: false,
        }));

        setPopupFiles(initialFiles);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getFileParts();
  }, [navigate, setShowUnauthenticatedPopup]);

  const handleUploadFileChange = (event) => {
    document.querySelector(".image-error").classList.add("hidden");
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var img = document.querySelector(".artwork-thumbnail");
        img.src = e.target.result;
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedUploadFile(file);
    }
  };

  const handleDragImage = async (event) => {
    event.preventDefault();

    document.querySelector(".image-error").classList.add("hidden");
    setSelectedUploadFile(event.dataTransfer.files[0]);
    setCroppedImage(null);

    var reader = new FileReader();
    var file = event.dataTransfer.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".artwork-thumbnail");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  function handleBeatNameChange(event) {
    setName(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (
      event.target.value.length === 0 ||
      !regex.test(event.target.value) ||
      event.target.value.length <= 2 ||
      event.target.value.length > 100
    )
      document.querySelector(".name-error").classList.remove("hidden");
    else document.querySelector(".name-error").classList.add("hidden");
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);

    if (event.target.value.length < 10 || event.target.value.length > 500)
      document.querySelector(".description-error").classList.remove("hidden");
    else document.querySelector(".description-error").classList.add("hidden");
  }

  function handleBPMChange(event) {
    const truncatedValue = event.target.value.slice(0, 4);

    setBPM(formatBPM(truncatedValue));

    if (event.target.value < 60)
      document.querySelector(".BPM-error").classList.remove("hidden");
    else document.querySelector(".BPM-error").classList.add("hidden");
  }
  function formatInputCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `${selectedCurrency === "GBP" ? "£" : "N"}${value}`;

    return value;
  }
  function formatBPM(value) {
    value = value.replace(/[^0-9]/g, "");

    return value;
  }
  function handlePriceChange(event) {
    setPrice(formatInputCurrency(event.target.value));
    const standardValue = convertRate(
      formatInputCurrency(event.target.value).slice(1)
    );

    if (parseInt(event.target.value?.slice(1)) <= 0) {
      document.querySelector(".price-error").classList.remove("hidden");

      document.querySelector(".price-error").classList.remove("hidden");
    } else if (
      (licenseType === "no" ? false : true) &&
      (standardValue > 10000 || standardValue < 50)
    ) {
      document.querySelector(".price-error").classList.remove("hidden");
    } else if (
      !(licenseType === "no" ? false : true) &&
      (standardValue > 250 || standardValue < 20)
    ) {
      document.querySelector(".price-error").classList.remove("hidden");
    } else {
      document.querySelector(".price-error").classList.add("hidden");
    }
  }

  function checkGenreChecked(obj) {
    for (let key in obj) {
      const skill = obj[key];
      if (
        skill &&
        typeof skill === "object" &&
        skill.hasOwnProperty("checked") &&
        skill.checked
      )
        return true;
    }

    return false;
  }

  function checkValues() {
    let valid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      name.length === 0 ||
      !regex.test(name) ||
      name.length > 100 ||
      name.length < 2
    ) {
      document.querySelector(".name-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".name-error").classList.add("hidden");
    }

    if (description.length < 10 || description.length > 500) {
      document.querySelector(".description-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".description-error").classList.add("hidden");
    }

    if (!checkGenreChecked(popupGenre)) {
      document.querySelector(".genre-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".genre-error").classList.add("hidden");
    }

    if (BPM < 60) {
      document.querySelector(".BPM-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".BPM-error").classList.add("hidden");
    }

    if (!checkGenreChecked(popupFiles) && licenseType === "yes") {
      document.querySelector(".files-error").classList.remove("hidden");
      valid = false;
    }

    if (parseInt(price?.slice(1)) <= 0) {
      document.querySelector(".price-error").classList.remove("hidden");

      document.querySelector(".price-error").classList.remove("hidden");
      valid = false;
    }
    const standardValue = convertRate(price?.slice(1));
    if (
      (licenseType === "no" ? false : true) &&
      (parseInt(standardValue) > 10000 || parseInt(standardValue) < 50)
    ) {
      document.querySelector(".price-error").classList.remove("hidden");
      valid = false;
    } else if (
      !(licenseType === "no" ? false : true) &&
      (parseInt(standardValue) > 250 || parseInt(standardValue) < 20)
    ) {
      document.querySelector(".price-error").classList.remove("hidden");
      valid = false;
    }

    if (!selectedUploadFile) {
      document.querySelector(".image-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".image-error").classList.add("hidden");
    }

    if (!selectedStreamingFile) {
      document.querySelector(".streaming-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".streaming-error").classList.add("hidden");
    }

    if (!selectedDownloadFile) {
      document.querySelector(".download-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".download-error").classList.add("hidden");
    }

    return valid;
  }

  useState(() => {
    const getGenres = async () => {
      try {
        const response = await api.get("genres");
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;

        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre, index) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setPopupGenre(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getGenres();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (saveChanges) {
      return;
    }

    setShowError(false);

    if (!isAuthenticated) {
      window.location.href = "/login";
      return;
    }

    if (!checkValues()) {
      setShowError(true);
      setErrorMessage("Incorrect values entered!");

      return;
    }

    setProgress(5);
    setSaveChanges(true);

    const genres = [];
    popupGenre.forEach((genre) => {
      if (genre.checked === true) {
        genres.push(genre.id);
      }
    });
    const files = [];
    popupFiles.forEach((genre) => {
      if (genre.checked === true) {
        files.push(genre.id);
      }
    });
    let body;
    const license = licenseType === "no" ? false : true;
    if (downloadOptions === "yes") {
      body = {
        name: name,
        description: description,
        bpm: parseInt(BPM),
        is_free: true,
        is_exclusive: license,
        is_biddable: false,
        artwork: selectedUploadFile?.name,
        preview: selectedStreamingFile?.name,
        purchase: selectedDownloadFile?.name,
        genres: [...genres],
        parts: [...files],
      };
    } else {
      body = {
        name: name,
        description: description,
        bpm: parseInt(BPM),
        is_free: false,
        is_exclusive: license,
        is_biddable: enableBidding === "yes" ? true : false,
        price:
          selectedCurrency === "GBP"
            ? price?.slice(1)
            : price?.slice(1) / rates,
        artwork: selectedUploadFile?.name,
        preview: selectedStreamingFile?.name,
        purchase: selectedDownloadFile?.name,
        genres: [...genres],
        parts: [...files],
      };
    }
    try {
      const response = await api.post(`beats`, body);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

      const data = response.data;
      if (response.status === 409) {
        setShowError(true);
        setErrorMessage(response.error.message);
        setSaveChanges(false);
        return;
      }
      if (response.status === 422) {
        setShowError(true);
        setErrorMessage(response.error.message);
        setSaveChanges(false);
        return;
      }
      if (response.status !== 200 && response.status !== 422) {
        uploadSuccess("Beat");
        navigate("/upload/failure");
      }

      setProgress(30);

      var img = document.querySelector(".artwork-thumbnail");
      const res = await fetch(img.src);
      const blob = await res.blob();
      const imageUpload = await fetch(data.artwork, {
        method: "PUT",
        body: blob,
      });

      if (imageUpload.status === 403) {
        setShowError(true);
        setErrorMessage("Authentication error! Please login again");
        setSaveChanges(false);
        return;
      }
      if (imageUpload.status !== 200) {
        uploadSuccess("Beat");
        navigate("/upload/failure");
        return;
      }
      setProgress(60);

      let mp3Upload = null;
      if (selectedStreamingFile.name.endsWith("wav")) {
        mp3Upload = await fetch(data.preview, {
          method: "PUT",
          body: selectedStreamingFile,
        });
      } else {
        const formData = new FormData();
        formData.append("file", selectedStreamingFile);
        mp3Upload = await fetch(data.preview, {
          method: "PUT",
          body: formData,
        });
      }

      if (mp3Upload.status === 200) {
        setProgress(80);
      } else {
        uploadSuccess("Beat");
        navigate("/upload/failure");
        return;
      }

      let zipUpload = null;
      if (selectedDownloadFile.name.endsWith("zip")) {
        zipUpload = await fetch(data.purchase, {
          method: "PUT",
          body: selectedDownloadFile,
        });
      } else {
        const formData = new FormData();
        formData.append("file", selectedDownloadFile);
        zipUpload = await fetch(data.purchase, {
          method: "PUT",
          body: formData,
        });
      }
      if (zipUpload.status === 200) {
        setProgress(100);
        uploadSuccess("Beat");
        navigate("/upload/success");
        return;
      } else {
        uploadSuccess("Beat");
        navigate("/upload/failure");
        return;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleStreamingFileChange = (event) => {
    document.querySelector(".streaming-error").classList.add("hidden");
    const maxSize = 100 * 1024 * 1024;

    if (event.target.files[0] && event.target.files[0].size > maxSize) {
      setMusicFileError(
        "Your file is too large. Please keep it under 100MB and try again."
      );
      document.querySelector(".streaming-error").classList.remove("hidden");

      setSelectedUploadFile(null);
      return;
    }
    setSelectedStreamingFile(event.target.files[0]);
  };
  const handleRemove = (event) => {
    document.querySelector(".image-error").classList.remove("hidden");
    document.querySelector(".artwork-thumbnail").src = null;
    setSelectedUploadFile(null);
    setImageSrc(null);
    setCroppedImage(null);
  };

  const handleDownloadFileChange = (event) => {
    document.querySelector(".download-error").classList.add("hidden");
    setSelectedDownloadFile(event.target.files[0]);
  };

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  const handleEnableBiddingRadioButtonChange = (event) => {
    setEnableBidding(event.target.value);
  };
  const handleDownloadOptionsRadioButtonChange = (event) => {
    setDownloadOptions(event.target.value);
  };
  const handleLicenseTypeRadioButtonChange = (event) => {
    setLicenseType(event.target.value);

    const currentFormat = selectedDownloadFile
      ? selectedDownloadFile.name.slice(-3)
      : "";

    if (
      !(
        (event.target.value === "no" &&
          (currentFormat === "mp3" ||
            currentFormat === "wav" ||
            currentFormat === "zip")) ||
        (event.target.value === "yes" &&
          (currentFormat === "rar" || currentFormat === "zip"))
      )
    ) {
      setSelectedDownloadFile(null);
    }
  };

  const handleFileCheckboxChange = (index) => {
    setPopupFiles((prevFiles) =>
      prevFiles.map((file, i) =>
        i === index ? { ...file, checked: !file.checked } : file
      )
    );

    setPopupFiles((updatedFiles) => {
      if (checkGenreChecked(updatedFiles)) {
        document.querySelector(".files-error").classList.add("hidden");
      } else {
        document.querySelector(".files-error").classList.remove("hidden");
      }
      return updatedFiles;
    });
  };

  const handleGenreCheckboxChange = (id) => {
    setPopupGenre((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: !genre.checked } : genre
      )
    );

    setPopupGenre((updatedGenre) => {
      if (checkGenreChecked(updatedGenre)) {
        document.querySelector(".genre-error").classList.add("hidden");
      } else {
        document.querySelector(".genre-error").classList.remove("hidden");
      }
      return updatedGenre;
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    document.querySelector(".streaming-error").classList.add("hidden");
    const file = event.dataTransfer.files[0];

    if (file && file.size > 100 * 1024 * 1024) {
      setMusicFileError(
        "Your file is too large. Please keep it under 100MB and try again."
      );
      document.querySelector(".streaming-error").classList.remove("hidden");
      setSelectedStreamingFile(null);
      return;
    }
    setSelectedStreamingFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <section className="upload-beats">
        <h1 className="upload-beats__heading-primary">Upload beats</h1>
        <h4 className="upload-beats__heading-secondary">General Information</h4>

        <form>
          <div className="upload-form__form-input">
            <div className="form-imput_beats">
              <label className="form-beats_label">
                Beat name
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="name-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Invalid name: Only letters, numbers, and spaces are allowed.
                </Popup>
              </label>
              <input
                type="text"
                className="form-beats-input input-name"
                placeholder="my beat dance-all"
                onChange={(event) => handleBeatNameChange(event)}
              />
              <label className="form-beats_label">
                Description
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="description-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  {description.length < 10
                    ? "Minimum characters are 10!"
                    : "Maximum characters are 500!"}
                </Popup>
              </label>
              <textarea
                className=" custom-textarea form-beats-input input-description"
                onChange={(event) => handleDescriptionChange(event)}
              ></textarea>
              <label className="form-beats_label">
                Primary Genre
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="genre-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Please select at least 1 genre!
                </Popup>
              </label>
              <Popup
                className="genre-dropdown"
                trigger={
                  <div className="form-beats-input form-option">
                    {!checkGenreChecked(popupGenre) && <>Select</>}
                    {checkGenreChecked(popupGenre) && (
                      <>
                        {popupGenre
                          .filter((genre) => genre.checked)
                          .slice(0, 7)
                          .map((genre) => (
                            <button
                              className="genre-remove-button"
                              key={genre.name}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleGenreCheckboxChange(
                                  popupGenre.findIndex((g) => g.id === genre.id)
                                );
                              }}
                            >
                              <img src={cross} alt="remove" />
                              {genre.name}
                            </button>
                          ))}
                        {popupGenre.filter((genre) => genre.checked).length >
                          7 && (
                          <div className="genre-remove-button">
                            <img src={more} alt="" />
                          </div>
                        )}
                      </>
                    )}
                    <img
                      src={downBtn}
                      style={
                        popupStates.genre
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" }
                      }
                      alt=""
                      className="upload-beat-genre-dropdown-button"
                    />
                  </div>
                }
                onOpen={() => handlePopupOpen("genre")}
                onClose={() => handlePopupClose("genre")}
                position="bottom center"
                arrow={false}
              >
                {popupGenre.map((genre, index) => {
                  return (
                    <div className="service-item" key={index}>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={genre.checked || false}
                          onChange={() => handleGenreCheckboxChange(index)}
                        />
                        {genre.name}
                      </label>
                    </div>
                  );
                })}
              </Popup>
              <label className="form-beats_label">
                BPM (beats per minute){" "}
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="BPM-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  BPM must be a number greater/equal to 60!
                </Popup>
              </label>
              <input
                type="tel"
                className="form-beats-input BPM input-bpm"
                onChange={(event) => handleBPMChange(event)}
                value={BPM}
              />

              <div>
                <p className="Checkmark-questions">Free Download Options</p>
                <div className="radio-btn-flex">
                  <label className="container">
                    No
                    <input
                      type="radio"
                      name="downloadOptions"
                      value="no"
                      checked={downloadOptions === "no"}
                      onChange={handleDownloadOptionsRadioButtonChange}
                    />
                    <span className="Checkmark"></span>
                  </label>
                  <label className="container">
                    Yes, with voice tag (MP3 Version)
                    <input
                      type="radio"
                      name="downloadOptions"
                      value="yes"
                      checked={downloadOptions === "yes"}
                      onChange={handleDownloadOptionsRadioButtonChange}
                    />
                    <span className="Checkmark"></span>
                  </label>
                </div>
              </div>
              {downloadOptions === "no" && (
                <div>
                  <p className="Checkmark-questions">
                    Do you want to enable bidding on beats by customers?
                  </p>

                  <div className="radio-btn-flex">
                    <label className="container">
                      No
                      <input
                        type="radio"
                        name="enableBidding"
                        value="no"
                        checked={enableBidding === "no"}
                        onChange={handleEnableBiddingRadioButtonChange}
                      />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="container">
                      Yes
                      <input
                        type="radio"
                        name="enableBidding"
                        value="yes"
                        checked={enableBidding === "yes"}
                        onChange={handleEnableBiddingRadioButtonChange}
                      />
                      <span className="Checkmark"></span>
                    </label>
                  </div>
                </div>
              )}
              {downloadOptions === "no" && (
                <div>
                  <p className="Checkmark-questions">License Type</p>
                  <div className="radio-btn-flex">
                    <label className="container">
                      Non-Exclusive
                      <input
                        type="radio"
                        name="licenseType"
                        value="no"
                        checked={licenseType === "no"}
                        onChange={handleLicenseTypeRadioButtonChange}
                      />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="container">
                      Exclusive
                      <Popup
                        className="warning-message-popup"
                        trigger={<img src={Info} alt="" />}
                        position="top center"
                        on="hover"
                        arrow="False"
                      >
                        Make sure you upload all the individual files for this
                        exclusive beat or you will not be able to claim your
                        funds
                      </Popup>
                      <input
                        type="radio"
                        name="licenseType"
                        value="yes"
                        checked={licenseType === "yes"}
                        onChange={handleLicenseTypeRadioButtonChange}
                      />
                      <span className="Checkmark"></span>
                    </label>
                  </div>
                </div>
              )}
              {licenseType === "yes" && (
                <div>
                  <label className="form-beats_label hide-group_label">
                    Files included in Zip Folder
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="files-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please select at least 1 file to upload!
                    </Popup>
                  </label>

                  <Popup
                    className="genre-dropdown file-dropdown"
                    trigger={
                      <div className="form-beats-input form-option hide-group_button">
                        {!checkGenreChecked(popupFiles) && <>Select Files</>}
                        {checkGenreChecked(popupFiles) && (
                          <>
                            {popupFiles
                              .filter((file) => file.checked)
                              .slice(0, 7)
                              .map((file) => (
                                <button
                                  className="genre-remove-button"
                                  key={file.name}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    handleFileCheckboxChange(
                                      popupFiles.findIndex(
                                        (f) => f.id === file.id
                                      )
                                    );
                                  }}
                                >
                                  <img src={cross} alt="remove" />
                                  {file.name}
                                </button>
                              ))}
                            {popupFiles.filter((file) => file.checked).length >
                              7 && (
                              <div className="genre-remove-button">
                                <img src={more} alt="" />
                              </div>
                            )}
                          </>
                        )}
                        <img
                          src={downBtn}
                          style={
                            popupStates.files
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                          alt=""
                          className="upload-beat-files-dropdown-button"
                        />
                      </div>
                    }
                    onOpen={() => handlePopupOpen("files")}
                    onClose={() => handlePopupClose("files")}
                    position="bottom center"
                    arrow={false}
                  >
                    {popupFiles.map((file, index) => (
                      <div className="service-item" key={index}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={file.checked}
                            onChange={() => handleFileCheckboxChange(index)}
                          />
                          {file.name}
                        </label>
                      </div>
                    ))}
                  </Popup>
                </div>
              )}
              {downloadOptions === "no" && (
                <div>
                  <p className="tax">
                    TTD will charge a 20% commission on for successful sales
                  </p>
                  <label className="form-beats_label">
                    License Price
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="price-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      {parseInt(price?.slice(1)) < 0
                        ? "Price must be greater than 0"
                        : (licenseType === "no" ? false : true) &&
                          (convertRate(price?.slice(1)) > 10000 ||
                            convertRate(price?.slice(1)) < 50)
                        ? `The price must be between ${formatCurrency(
                            5000
                          )} and ${formatCurrency(1000000)} for this beat.`
                        : `The price must be between ${formatCurrency(
                            2000
                          )} and ${formatCurrency(25000)} for this beat.`}
                    </Popup>
                  </label>
                  <input
                    type="tel"
                    className="form-beats-input liscense-price"
                    value={price}
                    placeholder={`${
                      licenseType === "yes"
                        ? formatCurrency(5000)
                        : formatCurrency(2000)
                    }`}
                    onChange={(event) => handlePriceChange(event)}
                  />
                </div>
              )}
            </div>

            <div className="form-input_artwork-upload">
              <div
                className="artwork_img-box"
                onDrop={handleDragImage}
                onDragOver={handleDragOver}
              >
                <h3>
                  Upload Cover Artwork
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="image-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a valid image!
                  </Popup>
                </h3>
                <p>Artwork Dimension</p>
                <p>Preferred: 1500x1500px, Minimum: 500x500px</p>
                <div>
                  {!selectedUploadFile && (
                    <label
                      htmlFor="file-upload-artwork"
                      className={`user-file-upload-label ${
                        selectedUploadFile ? "" : "upload-animation"
                      }`}
                    >
                      <img src={UploadCloud} alt="" />
                      Drag and drop picture here <br /> Or
                      <p className="upload-picture">Upload Picture</p>
                    </label>
                  )}
                  {!selectedUploadFile && (
                    <input
                      type="file"
                      id="file-upload-artwork"
                      accept="image/png, image/jpeg, image/gif, image/svg"
                      onChange={handleUploadFileChange}
                    />
                  )}
                  {imageSrc && (
                    <ImageCropper
                      imageSrc={imageSrc}
                      onCropComplete={setCroppedImage}
                    />
                  )}
                  {selectedUploadFile && (
                    <p className="profile-selected-file upload-animation">
                      <img
                        src={croppedImage ? croppedImage : Artwork}
                        alt=""
                        className="artwork-thumbnail"
                      />
                    </p>
                  )}
                  {selectedUploadFile && (
                    <div className="imageName-delete user-delete upload-animation">
                      <img src={Tick} alt="" className="tick" />
                      <span className="img_file-name">
                        {selectedUploadFile.name}
                      </span>
                      <img src={Remove} alt="" onClick={handleRemove} />
                    </div>
                  )}
                </div>
              </div>

              <div className="upload-files">
                <div onDrop={handleDrop} onDragOver={handleDragOver}>
                  <h3 className="upload-files_heading">
                    Upload Audio File For Streaming
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="streaming-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      {musicFileError}
                    </Popup>
                  </h3>

                  <h5 className="upload-files_secondary">
                    Preview File MP3 <br />
                    (MP3/WAV only)
                  </h5>
                  {!selectedStreamingFile && (
                    <label
                      htmlFor="file-upload-streaming"
                      className="file-upload-label"
                    >
                      <img src={MusicBtn} alt="" />
                      Please select an audio file (max 100MB).
                    </label>
                  )}
                  {!selectedStreamingFile && (
                    <input
                      type="file"
                      id="file-upload-streaming"
                      accept=".mp3, .wav"
                      ref={fileInputRef}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onChange={handleStreamingFileChange}
                    />
                  )}
                  {selectedStreamingFile && (
                    <p className="selected-file">
                      <img src={MusicBtn} alt="" />
                      <p>{selectedStreamingFile.name}</p>
                    </p>
                  )}
                </div>

                <div>
                  <h3 className="upload-files_heading">
                    Upload Audio File For Download
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="download-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please provide a valid setCart(null);
                      {licenseType === "yes" ? "ZIP/RAR" : "MP3/WAV/ZIP"} file!
                    </Popup>
                  </h3>

                  <h5 className="upload-files_secondary">
                    Purchase files (
                    {licenseType === "yes" ? "ZIP" : "MP3/WAV/ZIP"}) <br />(
                    {licenseType === "yes"
                      ? "ZIP or RAR only"
                      : "MP3/WAV/ZIP only"}
                    )
                  </h5>
                  {!selectedDownloadFile && (
                    <label
                      htmlFor="file-upload-download"
                      className="file-upload-label"
                    >
                      <img src={UploadBtn} alt="" />
                      Click to upload audio file here
                    </label>
                  )}
                  {!selectedDownloadFile && (
                    <input
                      type="file"
                      id="file-upload-download"
                      accept={
                        licenseType === "yes"
                          ? ".rar, .zip"
                          : ".mp3, .zip, .wav"
                      }
                      onChange={handleDownloadFileChange}
                      ref={downloadFileInputRef}
                    />
                  )}
                  {selectedDownloadFile && (
                    <p className="selected-file">
                      <img src={UploadBtn} alt="" />
                      <p>{selectedDownloadFile.name}</p>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="upload-container">
            <button className="save-changes" onClick={handleSubmit}>
              {saveChanges && (
                <Circle
                  percent={progress}
                  strokeWidth={10}
                  strokeColor="#fff"
                  style={{ height: "4rem", width: "4rem" }}
                  trailColor="#dfdfdf"
                />
              )}
              {!saveChanges && "Upload Beat"}
            </button>

            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className={`api-error ${!showError ? "hidden" : ""}`}
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {errorMessage}
            </Popup>
          </div>
        </form>
      </section>
    </div>
  );
}

export default UploadBeat;
